<template>
  <b-form-group
    :label="t('Xã/Phường')"
    :state="state"
  >
    <template v-slot:label>
      {{ t('Xã/Phường') }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="ward"
      v-model="ward"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name"
      :options="wards"
      :disabled="district == null"
      :placeholder="t('Chọn xã/phường')"
      @input="wardChanged"
      @open="onOpen"
    ><!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        {{ t('Không có dữ liệu') }}
      </template></v-select>
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt';
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    district: {
      type: Object,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ward: this.defaultValue,
      wards: [],
    };
  },

  watch: {
    district(newVal) {
      this.ward = null;
      this.wardChanged();
      if (newVal) {
        this.getWardsByDistrict(newVal);
      } else {
        this.wards = [];
      }
    },
  },
  methods: {
    wardChanged() {
      this.$emit('input', this.ward);
    },

    getWardsByDistrict(district) {
      if (district) {
        useJwt.getWards(district.id)
          .then(response => {
            this.wards = response.data;
          })
          .catch(() => {
            this.wards = [];
          });
      } else {
        this.wards = [];
      }
    },

    onOpen() {
      if (this.wards.length === 0) {
        this.getWardsByDistrict(this.district);
      }
    },
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
