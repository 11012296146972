<template>
  <b-modal
    id="modal-room"
    ref="refModal"
    :title="t('Phòng')"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    size="lg"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="!isLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="apartment"
              rules="required"
            >
              <select-apartment
                v-model="itemLocal.apartment"
                :filter-active="true"
                required
                :enable="itemLocal.allowSelectApartment || itemLocal.id <= 0"
                :enable-add="true"
                :default-value="itemLocal.apartment"
                :label="t('Tòa nhà')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="floor"
              rules="required"
            >
              <select-floor
                v-model="itemLocal.floor"
                required
                :default-value="itemLocal.floor"
                :label="t('Tầng')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                :label="t('Tên phòng')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Tên phòng') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="HomeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="P.101"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- Price -->
        <b-row>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="price"
              rules="required"
            >
              <number-input
                v-model="itemLocal.price"
                required
                :default-value="itemLocal.price"
                :label="t('Tiền thuê')"
                placeholder="5,000,000"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="deposit"
              rules="required"
            >
              <number-input
                v-model="itemLocal.deposit"
                required
                :default-value="itemLocal.deposit"
                :label="t('Tiền cọc')"
                placeholder="5,000,000"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="size"
              rules=""
            >
              <number-decimal-input
                v-model="itemLocal.size"

                :default-value="itemLocal.size"
                :label="t('Diện tích')"
                placeholder="30"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <number-input
              v-model="itemLocal.maxTenants"
              required
              :default-value="itemLocal.maxTenants"
              :label="t('Số khách tối đa')"
              placeholder="30"
            />
          </b-col>
        </b-row>
        <!-- Active/Inactive -->
        <b-row>
          <b-col
            cols="12"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.active"
                name="check-button"
                switch
                inline
              >
                {{ t('Hoạt động') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <select-invoice-template
              v-model="itemLocal.invoiceTemplate"
              :default-value="itemLocal.invoiceTemplate"
              :label="t('Mẫu in hóa đơn')"
            />
          </b-col>
        </b-row>
        <b-row hidden>
          <b-col>
            <select-contract-template
              v-model="itemLocal.contractTemplate"
              :default-value="itemLocal.contractTemplate"
              :label="t('Mẫu hợp đồng')"
            />
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BFormCheckbox,
  BRow,
  BCol,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectFloor from '@/views/components/SelectFloor.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectInvoiceTemplate from '@/views/components/SelectInvoiceTemplate.vue';
import SelectContractTemplate from '@/views/components/SelectContractTemplate.vue';
import NumberDecimalInput from '@/views/components/NumberDecimalInput.vue';
import useRoomModal from './useRoomModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BCol,
    BRow,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    NumberDecimalInput,
    SelectApartment,
    SelectFloor,
    NumberInput,
    SelectInvoiceTemplate,
    SelectContractTemplate,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    roomId: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      isLimitTenantsInRoom,
      onSubmit,
      t,
      isLoading,
      onOpen,
    } = useRoomModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      isLimitTenantsInRoom,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
      isLoading,
      onOpen,
    };
  },
};
</script>
