import useJwt from '@/auth/jwt/useJwt';

const path = 'location';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLocations(ctx, params) {
      return useJwt.getItems(path, params).then(response => response);
    },
    createLocation(ctx, data) {
      return useJwt.createItem(path, data).then(response => response);
    },
    updateLocation(ctx, data) {
      return useJwt.updateItem(path, data).then(response => response);
    },
    getLocationDetail(ctx, id) {
      return useJwt.getItemDetail(path, id).then(response => response);
    },
    deleteLocations(ctx, data) {
      return useJwt.deleteItem(path, data).then(response => response);
    },
  },
};
