import {
  ref, watch, computed,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { isEnableBed, parseDateToString } from '@/auth/utils';
import moment from 'moment';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import reservationStoreModule from '../reservationStoreModule';

export default function useReservationModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'reservation';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, reservationStoreModule);
  }
  const { t } = useI18nUtils();
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
  const isVMarket = process.env.VUE_APP_PROVIDER === 'vmarket';
  const toastification = toast();
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const isSubmitting = ref(false);
  const isLoading = ref(false);

  const resetModal = () => {
    resetItemLocal();
    isSubmitting.value = false;
  };

  const room = computed(() => itemLocal.value.room);
  const bed = computed(() => itemLocal.value.room);
  const billingDate = computed(() => itemLocal.value.billingDate);

  const itemId = computed(() => (props.item && props.item.id ? props.item.id : null));
  const fetchDetailData = () => {
    if (itemId.value) {
      isLoading.value = true;
      store.dispatch('reservation/getReservationDetail', itemId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };
  const onOpen = () => {
    resetModal();
    fetchDetailData();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      apartmentId: itemLocal.value.apartment.id,
      roomId: itemLocal.value.room.id,
      note: itemLocal.value.note,
      priceForRent: itemLocal.value.priceForRent,
      deposit: itemLocal.value.deposit,
      totalDeposit: itemLocal.value.totalDeposit,
      numberTenants: itemLocal.value.numberTenants,
      fees: itemLocal.value.fees ? itemLocal.value.fees.map(object => object.id) : [],
      depositMonth: itemLocal.value.depositMonthObject ? itemLocal.value.depositMonthObject.id : null,
      leadId: itemLocal.value.lead ? itemLocal.value.lead.id : null,
      saleId: itemLocal.value.sale ? itemLocal.value.sale.managerId : null,
      promotionMonth: itemLocal.value.promotionMonth ?? 0,
      promotionPricePerMonth: itemLocal.value.promotionPricePerMonth ?? 0,
    };

    if (itemLocal.value.bed && itemLocal.value.bed.id > 0) {
      data.bedId = itemLocal.value.bed.id;
    }
    if (itemLocal.value.tenant && itemLocal.value.tenant.id > 0) {
      data.tenantId = itemLocal.value.tenant.id;
    }
    if (itemLocal.value.cashbook && itemLocal.value.cashbook.id > 0) {
      data.cashbookId = itemLocal.value.cashbook.id;
    }
    if (itemLocal.value.depositTemplate && itemLocal.value.depositTemplate.id > 0) {
      data.depositTemplateId = itemLocal.value.depositTemplate.id;
    }
    if (itemLocal.value.paymentPeriod) {
      data.paymentPeriod = itemLocal.value.paymentPeriod.value;
    }
    const checkinDate = parseDateToString(itemLocal.value.checkinDate);

    data.checkinDate = checkinDate;
    data.depositDate = parseDateToString(itemLocal.value.depositDate);

    data.billingDate = parseDateToString(itemLocal.value.billingDate);
    data.startDate = parseDateToString(itemLocal.value.startDate);
    data.endDate = parseDateToString(itemLocal.value.endDate);

    // attachments
    if (itemLocal.value.attachments) {
      data.attachments = itemLocal.value.attachments.map(object => object.location);
    }

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      data.id = itemLocal.value.id;
      store
        .dispatch('reservation/updateReservation', data)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('reservation/createReservation', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  watch(room, val => {
    if (val && (itemLocal.value.id === 0 || isTingTong)) {
      itemLocal.value.priceForRent = val.price;
      if (!isTingTong) {
        itemLocal.value.deposit = val.deposit;
      }

      itemLocal.value.totalDeposit = val.deposit;
    }
  });
  watch(bed, val => {
    if (val && (itemLocal.value.id === 0 || isTingTong)) {
      itemLocal.value.priceForRent = val.price;
      if (!isTingTong) {
        itemLocal.value.deposit = val.deposit;
      }
      itemLocal.value.totalDeposit = val.deposit;
    } else if (room && (itemLocal.value.id === 0 || isTingTong)) {
      itemLocal.value.priceForRent = room.price;
      if (!isTingTong) {
        itemLocal.value.deposit = room.deposit;
      }
      itemLocal.value.totalDeposit = room.deposit;
    }
  });

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 4;
    }
    if (isBed) {
      return 0;
    }
    return 6;
  };

  const recalculateEndDate = (contractMonth, startDate) => {
    if (contractMonth && startDate) {
      if (moment(startDate, 'DD-MM-YYYY', true).isValid()) {
        itemLocal.value.endDate = moment(startDate, 'DD-MM-YYYY', true).add(contractMonth.value, 'months').subtract(1, 'day').format('DD-MM-YYYY');
      } else {
        itemLocal.value.endDate = moment(startDate).add(contractMonth.value, 'months').subtract(1, 'day').format('DD-MM-YYYY');
      }
    }
  };

  const onAddService = listServiceSelected => {
    // eslint-disable-next-line no-restricted-syntax
    for (const eachSelectedItem of listServiceSelected) {
      if (itemLocal.value.fees.filter(object => object.id === eachSelectedItem.id).length === 0) {
        itemLocal.value.fees = [...itemLocal.value.fees, eachSelectedItem];
      }
    }
  };

  const removeReservationFee = index => {
    itemLocal.value.fees.splice(index, 1);
  };

  watch(billingDate, val => {
    itemLocal.value.startDate = val;
  });

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    isTingTong,
    isVMarket,
    resetItemLocal,
    resetModal,
    onSubmit,
    resolveColWidthIfDisableBed,
    recalculateEndDate,
    onAddService,
    removeReservationFee,
    t,
    isLoading,
    onOpen,
  };
}
