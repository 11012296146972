<template>
  <div class="d-flex flex-column mb-1" @click="onMediaClicked">

    <div :class="['position-relative image-container mb-2']">
      <b-overlay
        :show="attachment.uploading"
        class="d-inline-block"
        rounded="true"
      >
        <b-img-lazy
          class="bg-white thumbnail"
          :style="{width: `${width} !important`, height: `${height} !important`, 'min-width': `80px`, 'min-height': `80px`}"
          v-bind="mainProps"
          fluid
          :src="getMediaSrc"
          alt="Image"
          @click="onMediaClicked"
        />
        <div
          v-if="previewMode === false"
          class="control-btns"
        >
          <button
            type="button"
            @click="removeFile"
          >
            <feather-icon
              icon="XIcon"
              size="15"
            />
          </button>
        </div>
        <div
          v-if="previewMode === false"
          class="control-btns-bottom"
        >
          <button
            type="button"
            @click="downloadFile"
          >
            <feather-icon
              icon="DownloadIcon"
              size="15"
            />
          </button>
        </div>
      </b-overlay>

    </div>
  </div>

</template>

<script>
import {
  BImgLazy,
  BOverlay,
} from 'bootstrap-vue';
import useJwt from '@/auth/jwt/useJwt';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BImgLazy,
    BOverlay,
  },
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    attachment: {
      type: Object,
      default: () => {},
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    targetObject: {
      type: Object,
      default: () => ({
        id: null,
        type: null,
      }),
    },
  },
  data: () => ({
    mainProps: {
      fluidGrow: true,
      blank: true,
      blankColor: '#bbb',
      class: 'm1',
    },
  }),
  computed: {
    getMediaSrc() {
      if (this.attachment) {
        const imageExtensions = ['png', 'jpg', 'jpeg', 'webp'];
        const videoExtensions = ['mp4', 'webm', 'ogg'];

        const extension = this.attachment.extension ? this.attachment.extension.toLowerCase() : null;
        const location = this.attachment.location ? this.attachment.location.toLowerCase() : null;

        if ((extension && videoExtensions.includes(extension)) || (location && videoExtensions.some(ext => location.includes(`.${ext}`)))) {
          const videoThumbnail = require('@/assets/images/icons/video.png');
          return videoThumbnail;
        }

        if ((extension && imageExtensions.includes(extension)) || (location && imageExtensions.some(ext => location.includes(`.${ext}`)))) {
          return this.attachment.src || this.attachment.location;
        }
        const mediaObj = {
          // eslint-disable-next-line global-require
          url: require('@/assets/images/icons/pdf.png'),
        };
        return mediaObj.url;
      }
      return this.attachment.src || this.attachment.location;
    },
  },
  created() {
    this.uploadAttachment();
  },
  methods: {

    removeFile() {
      // const { t } = useI18nUtils();
      if (this.targetObject.id && this.targetObject.type) {
        this.$bvModal
          .msgBoxConfirm(
            `Bạn có chắc chắn muốn xóa đính kèm này không?`,
            {
              title: `Xóa đính kèm`,
              okTitle: 'Xóa',
              cancelTitle: 'Huỷ',
              okVariant: 'danger',
              cancelVariant: 'outline-secondary',
            },
          )
          .then(value => {
            if (value) {
              useJwt.deleteAttachment({ ...this.targetObject, attachmentId: this.attachment.id }).then(() => {

              }).catch(() => {

              });
              this.$emit('on-delete');
            }
          });
      } else {
        this.$emit('on-delete');
      }
    },
    downloadFile() {
      window.location.href = this.attachment.location;
    },
    uploadAttachment() {
      if (this.attachment.uploadImediately) {
        const formData = new FormData();
        formData.append('file', this.attachment.file);
        if (this.targetObject.id && this.targetObject.type) {
          formData.append('id', this.targetObject.id);
          formData.append('type', this.targetObject.type);
        }
        useJwt.uploadAttachment(formData).then(response => {
          this.attachment.location = response.data;
          this.attachment.uploading = false;
          this.$emit('on-uploaded', this.attachment);
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              text: 'Upload thất bại, vui lòng thử lại sau',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
      }
    },
    onMediaClicked() {
      this.$emit('on-media-item-clicked');
    },
  },

};
</script>

<style lang="scss" scoped>

.image-container {
    width: fit-content;

    .empty-img {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .thumbnail {
        border: 1px solid rgba(200, 200, 200, 0.5);
        border-radius: 8px;
        aspect-ratio: 1;
    }
    .control-btns {
        > button, > label {
            border: 0;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 0 5px #c5c5c5;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            position: absolute;
            left: calc(100% - 15px);
            cursor: pointer;

            &:first-child {
                bottom: -8px;
            }

            &:last-child {
                top: -8px;
            }

            svg {
                stroke: gray;
            }

            &:hover {
                box-shadow: 0 0 3px #c5c5c5;
            }
        }
    }

    .control-btns-bottom {
        > button, > label {
            border: 0;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 0 5px #c5c5c5;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            position: absolute;
            left: calc(100% - 15px);
            bottom: calc(100% - 15px);
            cursor: pointer;

            &:first-child {
                bottom: -8px;
            }

            &:last-child {
                bottom: -8px;
            }

            svg {
                stroke: gray;
            }

            &:hover {
                box-shadow: 0 0 3px #c5c5c5;
            }
        }
    }
}
</style>
