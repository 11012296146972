<template>
  <div class="d-flex flex-column mb-1">
    <span
      v-if="label && label.length > 0"
      :class="`mb-1 ${align === 'center' && 'text-center'}`"
    >{{ label }} <span
      v-if="required"
      class="text-danger"
    >
      (*)
    </span></span>
    <div :class="['position-relative image-container mb-2', align==='center' && 'mx-auto']">
      <b-img-lazy
        v-if="getSrcImg"
        class="bg-white thumbnail"
        :style="{width: `${width}px !important`, height: `${height}px !important`}"
        v-bind="mainProps"
        fluid
        :src="getSrcImg"
        alt="Image"
      />
      <div
        v-else
        :style="{width: `${width}px`, height: `${height}px`}"
        class="empty-img thumbnail"
      >
        <feather-icon
          icon="ImageIcon"
          size="25"
        />
      </div>
      <input
        :id="id"
        ref="input"
        type="file"
        :name="id"
        class="d-none"
        accept="image/png, image/jpg, image/jpeg"
        @change="onChangeFile"
      >
      <div class="control-btns">
        <label :for="id">
          <feather-icon
            icon="Edit2Icon"
            size="15"
          />
        </label>
        <button
          v-if="imageSrc"
          type="button"
          @click="removeFile"
        >
          <feather-icon
            icon="XIcon"
            size="15"
          />
        </button>
      </div>
    </div>
    <small class="text-danger">{{ error && error.length > 0 ? error : errorMessage }}</small>
    <small
      v-if="instruction && instruction.length > 0"
      :class="`text-muted ${align === 'center' && 'text-center'}`"
    >
      {{ instruction }}
    </small>
  </div>

</template>

<script>
import {
  BImgLazy,
} from 'bootstrap-vue';

export default {
  components: {
    BImgLazy,
  },
  props: {
    align: {
      type: String,
      default: 'left',
    },
    src: {
      type: [String, null],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
    instruction: {
      type: String,
      default: 'Định dạng cho phép: PNG, JPG, JPEG. Dung lượng tối đa 10MB',
    },
    id: {
      type: String,
      default: 'file-input',
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    imageSrc: null,
    errorMessage: "",
    mainProps: {
      fluidGrow: true,
      blank: true,
      blankColor: '#bbb',
      class: 'm1',
    },
  }),
  computed: {
    getSrcImg() {
      const result = this.imageSrc || this.src;
      return result;
    },
  },
  methods: {
    onChangeFile({ target }) {
      const { files } = target;
      if (!files || !files.length) return;
      const file = files[0];
      if (!file.name.toLowerCase().match(/.*\.(jpg|png|jpeg)/g)) {
        this.removeFile();
        this.errorMessage = "File không đúng định dạng!";
      } else {
        const fr = new FileReader();
        fr.onload = () => {
          this.imageSrc = fr.result;
          this.$emit('onSelectFile', fr.result);
        };
        fr.readAsDataURL(file);
        this.errorMessage = "";
        this.$refs.input.files = files;
        this.$emit('input', file);
      }
    },
    removeFile() {
      if (this.$refs.input) {
        this.$refs.input.value = "";
      }
      this.errorMessage = "";
      this.imageSrc = null;
    },
  },
};
</script>

<style lang="scss" scoped>

.image-container {
    width: fit-content;

    .empty-img {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .thumbnail {
        border: 1px solid rgba(200, 200, 200, 0.5);
        border-radius: 8px;
        // box-shadow: 0 0 7px #c5c5c5;
    }
    .control-btns {
        > button, > label {
            border: 0;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 0 5px #c5c5c5;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            position: absolute;
            left: calc(100% - 15px);
            cursor: pointer;

            &:first-child {
                top: -8px;
            }

            &:last-child {
                bottom: -8px;
            }

            svg {
                stroke: gray;
            }

            &:hover {
                box-shadow: 0 0 3px #c5c5c5;
            }
        }
    }
}
</style>
