<template>
  <b-form-group
    :label="label"
    :state="state"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <cleave
      :id="id"
      v-model="number"
      :disabled="disabled"
      :class="['form-control', `${(error || '').length ? 'border-danger' : ''}`]"
      :raw="true"
      :options="options"
      :placeholder="placeholder"
      @input="onInputChange"
    />
    <small class="text-danger">{{
      error
    }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import Cleave from 'vue-cleave-component';

export default {
  components: {
    Cleave,
    BFormGroup,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: null,
    },
    tailPrefix: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      number: Number(this.defaultValue) || 0,
    };
  },
  watch: {
    defaultValue(val) {
      this.number = Number(val) || 0;
    },
  },
  methods: {
    onInputChange() {
      this.$emit('input', Number(this.number));
    },
  },
  setup(props) {
    const options = {
      numeral: true,
      numeralPositiveOnly: true,
      numeralThousandsGroupStyle: 'thousand',
      rawValueTrimPrefix: true,
      prefix: props.prefix || '',
      tailPrefix: props.tailPrefix || false,
      numeralDecimalScale: 2,
      numeralDecimalMark: '.',
      delimiter: ',',
    };

    return {
      options,
    };
  },
};
</script>
