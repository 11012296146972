<template>
  <div>
    <b-form-group
      :state="state"
    >
      <template
        v-if="label !== null"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="floor"
        v-model="floor"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="floors"
        :placeholder="t('Tầng')"
        @input="floorChanged"
        @open="onOpen"
      >
        <template
          v-if="enableAdd && $can('create', 'room')"
          #list-header
        >
          <li
            v-b-modal.modal-floor
            class="list-header d-flex align-items-center my-50"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="align-middle ml-25">{{ t('Thêm tầng') }}</span>
          </li>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          {{ t('Không có dữ liệu') }}
        </template></v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
    <floor-modal
      v-if="enableAdd"
      :item="{}"
      @refetch-data="fetchFloors"
      @on-item-created="onItemCreated"
    />
  </div>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import FloorModal from '../settings/floor/modal/FloorModal.vue';

export default {
  components: {
    BFormGroup,
    vSelect,
    FloorModal,
  },
  props: {
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Tầng',
    },
    enableAdd: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      floor: this.defaultValue,
      floors: [],
    };
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  created() {
    //this.fetchFloors();
  },
  methods: {
    floorChanged() {
      this.$emit('input', this.floor);
    },
    fetchFloors() {
      useJwt.getFloors().then(res => {
        this.floors = res.data.items;
      });
    },
    onOpen() {
      if (this.floors.length === 0) {
        this.fetchFloors();
      }
    },
    onItemCreated(newItem) {
      this.floor = newItem;
      this.floorChanged();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
