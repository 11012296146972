<template>
  <b-form-group
    :label="t('Chu kỳ thanh toán')"
    :state="state"
  >
    <template
      v-slot:label
    >
      {{ t('Chu kỳ thanh toán') }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="paymentPeriod"
      v-model="period"
      label="title"
      :options="paymentPeriods"
      :reduce="$root.reduce"
      :placeholder="t('Chọn chu kỳ thanh toán')"
      @input="valueChanged"
    />
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      period: this.defaultValue,
      paymentPeriods: [
        { title: '1 tháng', value: '1', id: '1' },
        { title: '2 tháng', value: '2', id: '2' },
        { title: '3 tháng', value: '3', id: '3' },
        { title: '4 tháng', value: '4', id: '4' },
        { title: '5 tháng', value: '5', id: '5' },
        { title: '6 tháng', value: '6', id: '6' },
        { title: '7 tháng', value: '7', id: '7' },
        { title: '8 tháng', value: '8', id: '8' },
        { title: '9 tháng', value: '9', id: '9' },
        { title: '10 tháng', value: '10', id: '10' },
        { title: '11 tháng', value: '11', id: '11' },
        { title: '1 năm', value: '12', id: '12' },
        { title: '2 năm', value: '24', id: '24' },
        { title: '3 năm', value: '36', id: '36' },
      ],
    };
  },

  methods: {
    valueChanged() {
      this.$emit('input', this.period);
    },

  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
