<template>
  <b-form-group
    :label="label"
    :state="state"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="type"
      v-model="type"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :options="options"
      :placeholder="t('Tiền cọc')"
      @input="valueChanged"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        {{ t('Không có dữ liệu') }}
      </template></v-select>
    <small class="text-danger">{{
      error
    }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      type: this.defaultValue,
      options: [
        { title: 'Không cọc', id: 0 },
        { title: '1 tháng', id: 1 },
        { title: '2 tháng', id: 2 },
        { title: '3 tháng', id: 3 },
        { title: '4 tháng', id: 4 },
        { title: '5 tháng', id: 5 },
        { title: '6 tháng', id: 6 },
        { title: '7 tháng', id: 7 },
        { title: '8 tháng', id: 8 },
        { title: '9 tháng', id: 9 },
        { title: '10 tháng', id: 10 },
        { title: '11 tháng', id: 11 },
        { title: '12 tháng', id: 12 },
      ],
    };
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.type);
    },
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
