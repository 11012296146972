import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRooms(ctx, params) {
      return useJwt.getRooms(params).then(response => response);
    },
    fetchAnalytic(ctx, params) {
      return useJwt.getRoomAnalytic(params).then(response => response);
    },
    exportRooms(ctx, params) {
      return useJwt.exportRooms(params).then(response => response);
    },
    createRoom(ctx, data) {
      return useJwt.createRoom(data).then(response => response);
    },
    updateRoom(ctx, data) {
      return useJwt.updateRoom(data).then(response => response);
    },
    getRoomDetail(ctx, id) {
      return useJwt.getRoomDetail(id).then(response => response);
    },
    deleteRooms(ctx, data) {
      return useJwt.deleteRooms(data).then(response => response);
    },
    generateImportRoomTemplate(ctx, data) {
      return useJwt.generateImportRoomTemplate(data).then(response => response);
    },
    importRooms(ctx, data) {
      return useJwt.importRooms(data).then(response => response);
    },
  },
};
