<template>
  <b-modal
    id="modal-lead-detail"
    ref="refModalLeadDetail"
    :title="t('Thông tin khách hẹn')"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <b-list-group class="">

        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ isTingTong ? t('Người bắn khách') : t('Người gửi tt khách') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.sale && itemLocal.sale.user ? itemLocal.sale.user.name : '' }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ isTingTong ? t('Người dẫn khách') : t('Người chăm sóc') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.performer && itemLocal.performer.user ? itemLocal.performer.user.name : '' }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.leadCollab">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('CTV tìm khách') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.leadCollab.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Trạng thái') }}:
            </b-col>
            <b-col class="">
              <b-badge
                class="font-small-1 mr-50 mt-50"
                :variant="`light-${itemLocal.leadStatusObject.variant}`"
              >{{ itemLocal.leadStatusObject.title }}</b-badge>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.failReason">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Lý do thất bại') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.failReason.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tên khách') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.tenant ? itemLocal.tenant.name : itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('SĐT khách') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.tenant ? itemLocal.tenant.phone : itemLocal.phone }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Giới tính') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.tenant && itemLocal.tenant.gender ? itemLocal.tenant.gender['name'] : itemLocal.genderObject ? itemLocal.genderObject.name : '' }}
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Nguồn khách') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.leadSourceObject ? itemLocal.leadSourceObject.name : '' }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Ghi chú') }}:
            </b-col>
            <b-col class="">
              <span style="white-space: pre-line;">{{ itemLocal.note }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tòa nhà') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.apartment ? itemLocal.apartment.name : '' }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Căn hộ') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.rooms.map(obj => obj.name).join(",") }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Giá thấp nhất') }}:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.expectedMinPrice).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Giá cao nhất') }}:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.expectedMaxPrice).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Giờ hẹn xem') }}:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.expectedVisitTime, "DD-MM-YYYY HH:mm") }}
            </b-col>
          </b-row>
        </b-list-group-item>

      </b-list-group>

      <!-- comment box -->
      <b-form-group class="mt-2">
        <b-form-textarea
          v-model="comment"
          rows="3"
          :placeholder="t('Thêm cập nhật, trao đổi')"
        />
      </b-form-group>
      <!--/ comment box -->

      <b-button
        size="sm"
        variant="primary"
        :disabled="isSubmitting"
        @click="submitComment"
      >
        {{ t('Bình luận') }}
      </b-button>

      <!-- comments -->
      <div
        v-for="(comment,ind) in itemLocal.comments.slice().reverse()"
        :key="ind"
        class="d-flex align-items-start mt-2"
      >
        <b-avatar
          :src="comment.user.avatar"
          size="34"
          class="mt-25 mr-75"
        />
        <div class="profile-user-info w-100">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">
              {{ comment.user.name }}
            </h6>
            <!-- <b-link class="text-body">
              <feather-icon
                icon="HeartIcon"
                size="18"
                class="mr-25"
                :class="comment.youLiked ? 'profile-likes' :'profile-icon'"
              />
              <span class="text-muted align-middle">{{ comment.commentsLikes }}</span>
            </b-link> -->
            <small>{{ parseDateToString(comment.created_at, 'DD-MM-YYYY HH:mm') }}</small>
          </div>
          <small>{{ comment.content }}</small>
        </div>
      </div>

    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
  BAvatar,
  BFormTextarea,
  BFormGroup,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { parseDateToString } from '@/auth/utils';
import useLeadDetailModal from './useLeadDetailModal';

export default {
  components: {
    BModal,
    BSpinner,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
    BAvatar,
    BFormTextarea,
    BFormGroup,
  },
  props: {
    leadId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      t,
      onDeleteAttachment,
      comment,
      isSubmitting,
      submitComment,
    } = useLeadDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      parseDateToString,
      t,
      onDeleteAttachment,
      comment,
      isSubmitting,
      submitComment,
      isTingTong,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
