import {
  computed,
  ref,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import leadStoreModule from '../leadStoreModule';

export default function useLeadDetailModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'lead';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, leadStoreModule);
  }
  const { t } = useI18nUtils();
  const toastification = toast();
  const refForm = ref(refFormObserver);
  const itemLocal = ref(null);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(null);
  };
  const isLoading = ref(false);
  const isSubmitting = ref(false);
  const comment = ref(null);

  const resetModal = () => {
    resetItemLocal();
  };

  const leadId = computed(() => props.leadId);

  const fetchDetailData = () => {
    if (leadId.value) {
      isLoading.value = true;
      store.dispatch('lead/getLeadDetail', leadId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const submitComment = () => {
    if (!comment.value || comment.value.length === 0) {
      return;
    }
    isSubmitting.value = true;
    store.dispatch('lead/commentLead', { leadId: leadId.value, content: comment.value })
      .then(response => {
        // itemLocal.value = response.data;
        itemLocal.value.comments = [...itemLocal.value.comments, response.data];
        comment.value = null;
        isSubmitting.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isSubmitting.value = false;
      });
  };

  const onOpen = () => {
    resetModal();
    fetchDetailData();
  };

  const onDeleteAttachment = () => { };

  return {
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    fetchDetailData,
    onDeleteAttachment,
    onOpen,
    isLoading,
    t,
    comment,
    isSubmitting,
    submitComment,
  };
}
