<template>
  <div>
    <b-form-group
      :label="label"
      :state="state"
    >
      <template
        v-if="label"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="room"
        v-model="room"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="rooms"
        :disabled="apartment == null || !enable"
        :placeholder="t('Phòng')"
        @input="roomChanged"
        @open="onOpen"
      >
        <template
          v-if="showStatus"
          #option="{ name, status }"
        >
          <span :class="`text-${status.variant}`">{{ name }}</span>
          <br>
          <cite :class="`text-${status.variant}`">{{ status ? status.title : '' }}</cite>
        </template>
        <template
          v-if="enableAdd === true && $can('create', 'room')"
          #list-header
        >
          <li
            v-b-modal.modal-room
            class="list-header d-flex align-items-center my-50"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="align-middle ml-25">{{ t('Thêm phòng') }}</span>
          </li>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          {{ t('Không có dữ liệu') }}
        </template></v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
    <room-modal
      v-if="enableAdd === true"
      :item="{active: true, apartment: apartment, allowSelectApartment: false}"
      @refetch-data="fetchRooms"
      @on-item-created="onItemCreated"
    />
  </div>

</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import RoomModal from '../property/room/modal/RoomModal.vue';

export default {
  components: {
    BFormGroup,
    vSelect,
    RoomModal,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    apartment: {
      type: Object,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    availableFor: {
      type: String,
      default: null,
    },
    filterActive: {
      type: Boolean,
      default: null,
    },
    showStatus: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      room: this.defaultValue,
      rooms: [],
    };
  },

  watch: {
    apartment(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.room = null;
        this.roomChanged();
        if (newVal) {
          this.fetchRooms();
        } else {
          this.rooms = [];
        }
      }
    },
  },
  methods: {
    roomChanged() {
      this.$emit('input', this.room);
    },
    onOpen() {
      if (this.rooms.length === 0) {
        this.fetchRooms();
      }
    },
    fetchRooms() {
      const filter = { apartmentId: this.apartment.id };
      if (this.filterActive) {
        filter.active = this.filterActive;
      }

      useJwt.selectItem('room', { filter, sort: { field: 'name', type: 'asc' } }).then(res => {
        this.rooms = res.data.items;
      });
    },
    onItemCreated(newItem) {
      this.room = newItem;
      this.roomChanged();
    },
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
.list-header {
    padding: $options-padding-y $options-padding-x;
      color: $secondary;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
