<template>
  <div>
    <p v-if="label && label.length > 0">
      {{ label }}
    </p>

    <b-row class="match-height mt-1">
      <b-col
        v-if="previewMode === false"
        md="2"
        cols="4"
      >
        <div
          class="image-container"
          :style="{ width: `${width}`, height: `${height}` }"
        >
          <label
            :for="id"
            :style="{ width: `${width}`, height: `${height}` }"
          >
            <div class="empty-img thumbnail">
              <feather-icon
                :for="id"
                icon="PlusIcon"
                size="50"
                variant="primary"
              />
            </div>
          </label>
          <input
            :id="id"
            ref="input"
            type="file"
            :name="id"
            class="d-none"
            :accept="accept"
            multiple
            @change="onChangeFile"
          >
        </div>
      </b-col>
      <b-col
        v-for="(attachment, index) in attachments"
        :key="index"
        md="2"
        cols="4"
        :attachment="attachment"
      >
        <media-item
          :preview-mode="previewMode"
          :attachment="attachment"
          :target-object="targetObject"
          @on-delete="onDeleteItemAtIndex(index)"
          @on-uploaded="onUploadedAttachmentAtindex(index, $event)"
          @on-media-item-clicked="onMediaItemClicked(index)"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showDownloadModal"
      size="xl"
      title=""
      centered
      hide-footer
      style="background-color: black;"
      @hide="resetDownloadModal"
    >
      <div
        class="modal-image-container d-flex align-items-center justify-content-center position-relative"
      >
        <div id="wrap">
          <span><strong>{{ currentIndex + 1 }} / {{ attachments.length }}</strong></span>
          <ul>
            <li
              v-for="(attachment, index) in attachments"
              :key="index"
              :class="{ on: index === currentIndex }"
            >
              <img
                v-if="getFileType(attachment) === 'image' || getFileType(attachment) === 'document'"
                :src="attachment.location"
                :alt="index"
                style="max-width: 100%; max-height: 80vh"
                class="img-class"
                @error="setDefaultImage"
              >
              <video
                v-else-if="getFileType(attachment) === 'video'"
                :src="attachment.location"
                controls
                style="max-width: 100%; max-height: 80vh"
                class="img-class"
              />
            </li>
          </ul>

          <button
            class="control-button prev"
            style="margin-left: 50px"
            @click="roll('prev')"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="24"
            />
          </button>

          <BButton
            v-if="!playing"
            variant="outline-primary"
            class="control-button play-pause"
            @click="play"
          >
            <feather-icon
              icon="PlayIcon"
              size="24"
            />
          </BButton>
          <button
            class="control-button zoom"
            @click="toggleZoom"
          >
            <feather-icon
              :icon="isZoomed ? 'ZoomOutIcon' : 'ZoomInIcon'"
              size="24"
            />
          </button>
          <BButton
            v-if="playing"
            variant="outline-primary"
            class="control-button play-pause"
            @click="pause"
          >
            <feather-icon
              icon="PauseIcon"
              size="24"
            />
          </BButton>
          <button
            class="control-button fas fa-forward"
            @click="roll('next')"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="24"
            />
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BModal, BButton,
} from "bootstrap-vue";
import MediaItem from "./MediaItem.vue";

let timer = null;
const AUTO_INTERVAL = 2000;
export default {
  components: {
    BRow,
    BModal,
    BButton,
    BCol,
    MediaItem,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    instruction: {
      type: String,
      default: "Định dạng cho phép: PNG, JPG, JPEG. Dung lượng tối đa 10MB",
    },
    id: {
      type: String,
      default: "file-input",
    },
    initialValue: {
      type: Array,
      default: () => [],
    },
    targetObject: {
      type: Object,
      default: () => ({
        id: null,
        type: null,
      }),
    },
    uploadImediately: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playing: false,
      currentIndex: 0,
      showDownloadModal: false,
      viewMediaModal: false,
      isZoomed: false,
      attachments: this.initialValue,
      accept:
        "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf,image/png,image/jpeg,audio/mpeg,video/mp4,video/mpeg",
    };
  },

  watch: {
    initialValue(val) {
      this.attachments = val;
    },
  },
  methods: {
    onChangeFile({ target }) {
      const { files } = target;
      if (!files || !files.length) return;

      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        if (
          !file.name
            .toLowerCase()
            .match(
              /.*\.(jpg|png|jpeg|pdf|doc|docx|xls|xlsx|mp3|mp4|mov|ppt|pptx)/g,
            )
        ) {
          //
        } else {
          const vm = this;
          const fr = new FileReader();

          const extension = file.name.split(".").pop();

          fr.onload = () => {
            const attachment = {
              file,
              src: fr.result,
              id: new Date().getTime(),
              extension,
              uploading: this.uploadImediately,
              uploadImediately: this.uploadImediately,
            };
            vm.attachments.push(attachment);
            vm.onChange();
          };
          fr.readAsDataURL(file);
        }
      }
    },
    onDeleteItemAtIndex(index) {
      if (
        this.attachments[index].location
        && this.attachments[index].location.length > 0
      ) {
        this.$emit("on-delete-uploaded-file", this.attachments[index].id);
      }
      this.attachments = this.attachments.filter(
        obj => obj.id !== this.attachments[index].id,
      );

      this.onChange();
    },
    onUploadedAttachmentAtindex(index, attachment) {
      this.attachments[index] = attachment;

      this.onChange();
    },
    onChange() {
      this.$emit("input", this.attachments);
    },

    // slide image
    setAutoRoll() {
      const vueSelf = this;
      timer = setInterval(() => {
        vueSelf.addIndex();
      }, AUTO_INTERVAL);
    },
    addIndex() {
      const newIndex = this.currentIndex + 1;
      this.currentIndex = newIndex === this.attachments.length ? 0 : newIndex;
    },
    roll(direction) {
      const diff = direction === "prev" ? -1 : 1;
      this.currentIndex = this.getTargetIndex(diff);
      if (this.playing) {
        clearInterval(timer);
        this.setAutoRoll();
      }
    },
    play() {
      this.setAutoRoll();
      this.playing = true;
    },
    pause() {
      clearInterval(timer);
      this.playing = false;
    },
    getTargetIndex(diff) {
      const { length } = this.attachments;
      const index = this.currentIndex + diff;
      if (index === -1) {
        return length - 1;
      }
      if (index === length) {
        return 0;
      }
      return index;
    },
    toggleZoom() {
      const imgElements = document.querySelectorAll(".img-class");
      imgElements.forEach(imgElement => {
        if (this.isZoomed) {
          imgElement.style.transform = "scale(1)";
        } else {
          imgElement.style.transform = "scale(2)";
        }
      });
      this.isZoomed = !this.isZoomed;
    },
    onMediaItemClicked(index) {
      this.currentIndex = index;
      this.showDownloadModal = true;
    },
    setDefaultImage(event) {
      event.target.src = require('@/assets/images/icons/pdf-v1.png');
    },

    resetDownloadModal() {
      this.showDownloadModal = false;
    },
    getFileType(attachment) {
      if (!attachment || !attachment.location) {
        return 'unknown'; // or handle as you see fit
      }
      const imageExtensions = ['jpg', 'jpeg', 'png'];
      const videoExtensions = ['mp4', 'mov', 'avi'];
      const extension = attachment.location.split('.').pop().toLowerCase();

      if (imageExtensions.includes(extension)) {
        return 'image';
      } if (videoExtensions.includes(extension)) {
        return 'video';
      }
      return 'document';
    },
  },
};
</script>

<style lang="scss" scoped>
.control-button.zoom {
  position: fixed;
  bottom: 10px;
  z-index: 1050;
  top: 158px;
  left: 2010px;

}
.control-button {
  width: 50px;
  height: 50px;
  margin: 4px;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.control-button.play-pause {
  top: 30px;
  right: 10px;
  z-index: 10;
  padding-left: 17px;
}

.modal-image-container {
  max-width: 90vw;
  max-height: 80vh;
  overflow: hidden;
}

#wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

li {
  display: none;
  width: 100%;
  height: 100%;
}

li.on {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-class {
  width: 1000px;
  height: 1000px;
  object-fit: contain;
}

.fa-backward {
  left: 10px;
}

.fa-forward {
  right: 10px;
}

.fa-play,
.fa-pause {
  left: calc(50% - 25px);
}

span {
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 0 6px;
  color: lightgray;
  background-color: rgba(0, 0, 0, 0.5);
}

.image-container {
  aspect-ratio: 1;

  .empty-img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .thumbnail {
    border: 1px solid rgba(200, 200, 200, 0.5);
    border-radius: 8px;
    aspect-ratio: 1;
  }

  .modal-dialog {
    max-width: 100%;
    margin: 0;
    height: 100vh;
  }

  .modal-body {
    height: 100vh;
    overflow-y: auto;
    background-color: black;
  }

  .modal-image-container {
    width: 100%;
    height: 100%;
    padding: 0;
  }
}
</style>
