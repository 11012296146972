import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchQuotas(ctx, params) {
      return useJwt.getQuotas(params).then(response => response);
    },
    createQuota(ctx, data) {
      return useJwt.createQuota(data).then(response => response);
    },
    deleteQuotas(ctx, data) {
      return useJwt.deleteQuotas(data).then(response => response);
    },
    getDetailQuota(ctx, data) {
      return useJwt.getQuotaDetail(data).then(response => response);
    },
    updateQuota(ctx, data) {
      return useJwt.updateQuota(data).then(response => response);
    },
  },
};
