import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import leadStoreModule from '../leadStoreModule';

export default function useForwardLeadModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'lead';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, leadStoreModule);
  }
  const { t } = useI18nUtils();

  const toastification = toast();
  const isSubmitting = ref(false);
  const refForwardModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify({ ...props.item, note: '' })));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify({ ...props.item, note: '' }));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      id: itemLocal.value.id,
      note: itemLocal.value.note,
    };

    isSubmitting.value = true;

    let dispatchName = 'lead/fowardLeadStatus';
    if (itemLocal.value.isMarkAsFail) {
      dispatchName = 'lead/markLeadAsFail';
      data.failReasonId = itemLocal.value.failReason ? itemLocal.value.failReason.id : null;
    } else {
      data.performerId = itemLocal.value.performer ? itemLocal.value.performer.managerId : null;
    }

    store
      .dispatch(dispatchName, data)
      .then(response => { emit('on-item-updated', response.data); })
      .then(() => {
        refForwardModal.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastUpdateSuccess();
        isSubmitting.value = false;
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  return {
    refForwardModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    t,
  };
}
