import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCashbooks(ctx, params) {
      return useJwt.getCashbooks(params).then(response => response);
    },
    getCashbookDetail(ctx, params) {
      return useJwt.getCashbookDetail(params).then(response => response);
    },
    createCashbook(ctx, data) {
      return useJwt.createCashbook(data).then(response => response);
    },
    updateCashbook(ctx, data) {
      return useJwt.updateCashbook(data).then(response => response);
    },
    deleteCashbooks(ctx, data) {
      return useJwt.deleteCashbooks(data).then(response => response);
    },
  },
};
