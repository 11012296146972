<template>
  <b-form-group :label="label" :state="state">
    <template v-if="label" v-slot:label>
      {{ label }}
      <span v-if="required" class="text-danger"> (*) </span>
    </template>
    <v-select
      id="sale"
      v-model="sale"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name"
      :options="sales"
      :selectable="(option) => !ignoreIds.includes(option.id)"
      :placeholder="t(placeholder)"
      :multiple="multiple"
      @input="valueChanged"
      ><!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        {{ t("Không có dữ liệu") }}
      </template></v-select
    >
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Vui lòng chọn",
    },
    defaultValue: {
      type: [Array],
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    includeOwner: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    ignoreIds: {
      type: Array,
      default: () => [],
    },
    apartment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sale: this.defaultValue,
      sales: [],
    };
  },
  watch: {
    apartment(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchSales();
      }
    },
  },
  created() {
    this.fetchSales();
  },
  methods: {
    valueChanged() {
      this.$emit("input", this.sale);
    },
    fetchSales() {
      let filter = { includeOwner: true };
      if (this.apartment) {
        filter = { ...filter, apartmentId: this.apartment.id };
      }
      useJwt.getManagers({ filter, perPage: 1000 }).then((res) => {
        this.sales = res.data.items
          .map((obj) => ({ ...obj.user, managerId: obj.id ? obj.id : null }))
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          );
        console.log(this.sales);

        //
        // if (this.sale && this.sales.filter(obj => obj.id === this.sale.id).length === 0) {
        //   this.sale = null;
        //   this.valueChanged();
        // }
      });
    },
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/vue-select.scss";
</style>
