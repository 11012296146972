var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label":_vm.label,"state":_vm.state},scopedSlots:_vm._u([(_vm.label)?{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")]):_vm._e()]},proxy:true}:null],null,true)},[_c('v-select',{attrs:{"id":"tenant","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"get-option-label":function (option) { return option.name; },"options":_vm.tenants,"placeholder":_vm.t('Khách hàng'),"disabled":!_vm.enable},on:{"input":_vm.valueChanged,"click":_vm.onClick,"search":_vm.onSearchResult},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var phone = ref.phone;
return [_vm._v(" "+_vm._s(name)+" "),_c('br'),_c('cite',[_vm._v(_vm._s(phone))])]}},(_vm.enableAdd === true && _vm.$can('create', 'tenant'))?{key:"list-header",fn:function(){return [_c('li',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-tenant",modifiers:{"modal-tenant":true}}],staticClass:"list-header d-flex align-items-center my-50"},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"}}),_c('span',{staticClass:"align-middle ml-25"},[_vm._v(_vm._s(_vm.t('Thêm khách hàng')))])],1)]},proxy:true}:null,{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" "+_vm._s(_vm.t('Không có dữ liệu'))+" ")]}}],null,true),model:{value:(_vm.tenant),callback:function ($$v) {_vm.tenant=$$v},expression:"tenant"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error))])],1),(_vm.enableAdd === true)?_c('tenant-modal',{attrs:{"item":{individual: true},"is-add-lead":_vm.isAddLead},on:{"refetch-data":_vm.fetchTenants,"on-item-created":_vm.onItemCreated}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }