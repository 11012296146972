<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <b-modal
    id="modal-reservation"
    ref="refModal"
    :title="t('Đặt cọc')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="!isLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row class="mt-2">
          <b-col>
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary mb-1">
              {{ t('Người bắn khách') }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <!-- Assignee -->
            <validation-provider
              #default="validationContext"
              name="sale"
              :rules="isTingTong ? 'required' : ''"
            >
              <select-manager
                v-model="itemLocal.sale"
                :required="isTingTong ? true : false"
                :label="t('Người bắn khách')"
                :default-value="itemLocal.sale && itemLocal.sale.user ? [{...itemLocal.sale.user, managerId: itemLocal.sale.id}] : []"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary mb-1">
              {{ t('Thông tin khách') }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="apartment"
              rules="required"
            >
              <select-apartment
                v-model="itemLocal.apartment"
                required
                :filter-active="true"
                :enable-add="true"
                :default-value="itemLocal.apartment"
                :label="t('Tòa nhà')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="room"
              rules="required"
            >
              <select-room
                v-model="itemLocal.room"
                :filter-active="true"
                required
                :enable-add="true"
                available-for="reservation"
                :default-value="itemLocal.room"
                :label="t('Phòng')"
                :apartment="itemLocal.apartment"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                :show-status="true"
              />
            </validation-provider>
          </b-col>

        </b-row>
        <b-row>
          <b-col
            v-if="resolveColWidthIfDisableBed(true) > 0"
            :md="resolveColWidthIfDisableBed()"
          >
            <validation-provider
              #default="validationContext"
              name="bed"
              rules=""
            >
              <select-bed
                v-model="itemLocal.bed"
                :default-value="itemLocal.bed"
                :filter-active="true"
                :label="t('Giường')"
                :room="itemLocal.room"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                :enable="itemLocal.room && itemLocal.room.numberActiveBeds > 0"
              />
            </validation-provider>
          </b-col>
          <b-col :md="resolveColWidthIfDisableBed()">
            <validation-provider
              #default="validationContext"
              name="tenant"
              rules="required"
            >
              <select-tenant
                v-model="itemLocal.tenant"
                required
                :default-value="itemLocal.tenant"
                :label="t('Khách cọc')"
                :enable-add="true"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col :md="resolveColWidthIfDisableBed()">
            <validation-provider
              #default="validationContext"
              name="numberTenants"
              rules=""
            >
              <number-input
                v-model="itemLocal.numberTenants"
                :default-value="itemLocal.numberTenants"
                :label="t('Số người ở')"
                placeholder="3"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>

          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="priceForRent"
              rules="required"
            >
              <number-input
                v-if="!isTingTong"
                v-model="itemLocal.priceForRent"
                required
                :default-value="itemLocal.priceForRent"
                :label="t('Tiền thuê (thỏa thuận)')"
                placeholder="5,000,000"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
              <b-form-group
                v-else
                :label="t('Tiền thuê (thỏa thuận)')"
              >
                <b-form-input
                  :value="Number(itemLocal.priceForRent).toLocaleString()"
                  readonly
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="totalDeposit"
              rules="required"
            >
              <select-deposit-month
                v-if="isTingTong"
                v-model="itemLocal.depositMonthObject"
                :default-value="itemLocal.depositMonthObject"
                :label="t('Tiền cọc (full)')"
                required
              />
              <number-input
                v-else
                v-model="itemLocal.totalDeposit"
                required
                :default-value="itemLocal.totalDeposit"
                :label="t('Tiền cọc (full)')"
                placeholder="5,000,000"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
              <!-- <b-form-group
                v-else
                label="Tiền cọc (full)"
              >
                <b-form-input
                  :value="Number(itemLocal.totalDeposit).toLocaleString()"
                  readonly
                />
              </b-form-group> -->
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="deposit"
              rules="required"
            >
              <number-input
                v-model="itemLocal.deposit"
                required
                :default-value="itemLocal.deposit"
                :label="t('Tiền cọc (tạm cọc)')"
                placeholder="5,000,000"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="discount"
              rules="required|maxValue:60"
            >
              <number-input
                v-model="itemLocal.promotionMonth"
                required
                :label="t('Số tháng giảm')"
                :default-value="itemLocal.promotionMonth"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="Số tiền giảm mỗi tháng"
              rules="required"
            >
              <number-input
                v-model="itemLocal.promotionPricePerMonth"
                required
                :label="t('Số tiền giảm mỗi tháng')"
                :default-value="itemLocal.promotionPricePerMonth"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="depositDate"
              rules="required"
            >
              <select-date
                v-model="itemLocal.depositDate"
                required
                :default-value="itemLocal.depositDate"
                :label="t('Ngày đặt cọc')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="checkinDate"
              rules="required"
            >
              <select-date
                v-model="itemLocal.checkinDate"
                required
                :default-value="itemLocal.checkinDate"
                :label="t('Ngày chuyển vào')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="billingDate"
              :rules="isTingTong ? 'required' : ''"
            >
              <select-date
                v-model="itemLocal.billingDate"
                :required="isTingTong"
                :default-value="itemLocal.billingDate"
                :label="t('Ngày bắt đầu tính tiền')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="contractMonth"
              rules=""
            >
              <select-contract-month
                v-model="itemLocal.contractMonth"
                :default-value="itemLocal.contractMonth"
                :label="t('Thời hạn hợp đồng')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                @input="recalculateEndDate(itemLocal.contractMonth, itemLocal.startDate)"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="startDate"
              rules=""
            >
              <select-date
                v-model="itemLocal.startDate"
                :default-value="itemLocal.startDate"
                :label="t('Thuê từ ngày')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                @input="recalculateEndDate(itemLocal.contractMonth, itemLocal.startDate)"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="endDate"
              rules=""
            >
              <select-date
                v-model="itemLocal.endDate"
                :default-value="itemLocal.endDate"
                :label="t('Thuê đến ngày')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col cols="6">
            <select-sale
              v-model="itemLocal.sale"
              :default-value="itemLocal.sale"
              label="Nhân viên sale"
            />
          </b-col> -->
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Chu kỳ thanh toán"
              rules=""
            >
              <select-payment-period
                v-model="itemLocal.paymentPeriod"
                :default-value="itemLocal.paymentPeriod"
                :label="t('Chu kỳ thanh toán')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              :name="isTingTong ? t('Hình thức thanh toán') : t('Sổ quỹ')"
              rules="required"
            >
              <select-cashbook
                v-model="itemLocal.cashbook"
                required
                :enable-add="true"
                :default-value="itemLocal.cashbook"
                :label="isTingTong ? t('Hình thức thanh toán') : t('Sổ quỹ')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                :apartment-id="itemLocal.apartment ? itemLocal.apartment.id : null"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Mẫu hợp đồng cọc"
              rules=""
            >
              <select-deposit-template
                v-model="itemLocal.depositTemplate"
                :default-value="itemLocal.depositTemplate"
                :label="t('Mẫu hợp đồng cọc')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group :label="t('Ghi chú')">
              <b-form-textarea
                id="note"
                v-model="itemLocal.note"
                :placeholder="t('Không nuôi động vật và thêm KM nếu có')"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="itemLocal.apartment">

          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                {{ t('Tiền phí dịch vụ') }}
              </div>
              <b-button
                v-b-modal.modal-list-service
                variant="primary"
                class="btn-icon ml-auto"
                size="sm"
              >
                <feather-icon icon="PlusSquareIcon" />
              </b-button>
            </div>
          </b-col>

        </b-row>
        <div>
          <b-row
            v-for="(eachFee, index) in itemLocal.fees"

            :id="eachFee.id"
            :key="eachFee.id"
            ref="row"
          >
            <b-col cols="10">
              <span>{{ eachFee.name }}</span>
            </b-col>

            <b-col
              cols="2"
              sm="2"
              class="px-0 mt-sm-2"
            >
              <b-button
                variant="outline-danger"
                class="btn px-1 btn-outline-danger ml-1 ml-sm-auto float-sm-right mr-sm-1 w-90 min-w-75"
                @click="removeReservationFee(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>{{ t('Xoá') }}</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </div>

        <b-row v-if="!itemLocal.id">
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                {{ t('Đính kèm') }}
              </div>
            </div>
          </b-col>
          <b-col cols="12">

            <media-view
              v-model="itemLocal.attachments"
              :label="null"
              :initial-value="itemLocal.attachments"
              :upload-imediately="true"
            />

          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
    <list-service-modal
      :item-local="itemLocal"
      :fees-selected="itemLocal.fees"
      :apartment="itemLocal.apartment"
      @selectService="onAddService"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormTextarea,
  BButton,
  BFormInput,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectBed from '@/views/components/SelectBed.vue';
import SelectTenant from '@/views/components/SelectTenant.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectCashbook from '@/views/components/SelectCashbook.vue';
import SelectDepositTemplate from '@/views/components/SelectDepositTemplate.vue';
import SelectContractMonth from '@/views/components/SelectContractMonth.vue';
import SelectPaymentPeriod from '@/views/components/SelectPaymentPeriod.vue';
import SelectDepositMonth from '@/views/components/SelectDepositMonth.vue';
import MediaView from '@/views/components/media/MediaView.vue';
import SelectManager from '@/views/components/SelectManager.vue';
import useReservationModal from './useReservationModal';
import ListServiceModal from '../../contract/listServiceModal/ListServiceModal.vue';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormInput,
    SelectDate,
    SelectApartment,
    SelectRoom,
    SelectBed,
    SelectTenant,
    SelectCashbook,
    NumberInput,
    SelectDepositTemplate,
    SelectContractMonth,
    SelectPaymentPeriod,
    ListServiceModal,
    SelectDepositMonth,
    MediaView,
    SelectManager,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      isTingTong,
      isVMarket,
      resetItemLocal,
      resetModal,
      resolveColWidthIfDisableBed,
      onSubmit,
      recalculateEndDate,
      onAddService,
      removeReservationFee,
      t,
      isLoading,
      onOpen,
    } = useReservationModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      isTingTong,
      isVMarket,
      resetItemLocal,
      resetModal,
      onSubmit,
      resolveColWidthIfDisableBed,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      recalculateEndDate,
      onAddService,
      removeReservationFee,
      t,
      isLoading,
      onOpen,
    };
  },
};
</script>
