import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import feeStoreModule from '../feeStoreModule';

export default function useFeeModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'fee';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, feeStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const isLoading = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const feeId = computed(() => props.feeId);

  const fetchDetailData = () => {
    if (feeId.value) {
      isLoading.value = true;
      store.dispatch('fee/getFeeDetail', feeId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const onOpen = () => {
    resetModal();
    fetchDetailData();
  };
  const { t } = useI18nUtils();
  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      name: itemLocal.value.name,
      description: itemLocal.value.description,
    };

    if (itemLocal.value.price) {
      data.price = itemLocal.value.price;
    }

    if (itemLocal.value.type && itemLocal.value.type.id > 0) {
      data.typeId = itemLocal.value.type.id;
    }

    if (itemLocal.value.feeCategory && itemLocal.value.feeCategory.id) {
      data.feeCategoryId = itemLocal.value.feeCategory.id;
    }

    if (itemLocal.value.unit && itemLocal.value.unit.value) {
      data.unit = itemLocal.value.unit.value;
    }
    if (itemLocal.value.quota && itemLocal.value.quota.id > 0) {
      data.quotaId = itemLocal.value.quota.id;
    }
    if (itemLocal.value.apartments) {
      data.apartmentIds = itemLocal.value.apartments.map(_obj => _obj.id);
    }

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      data.id = itemLocal.value.id;
      store
        .dispatch('fee/updateFee', data)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('fee/createFee', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    t,
    isLoading,
    onOpen,
  };
}
