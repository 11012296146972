import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLeads(ctx, params) {
      return useJwt.getLeads(params).then(response => response);
    },
    fetchAnalytics(ctx, params) {
      return useJwt.getLeadAnalytics(params).then(response => response);
    },
    exportLeads(ctx, params) {
      return useJwt.exportLeads(params).then(response => response);
    },
    createLead(ctx, data) {
      return useJwt.createLead(data).then(response => response);
    },
    updateLead(ctx, data) {
      return useJwt.updateLead(data).then(response => response);
    },
    getLeadDetail(ctx, data) {
      return useJwt.getLeadDetail(data).then(response => response);
    },
    deleteLeads(ctx, data) {
      return useJwt.deleteLeads(data).then(response => response);
    },
    fowardLeadStatus(ctx, data) {
      return useJwt.fowardLead(data).then(response => response);
    },
    markLeadAsFail(ctx, data) {
      return useJwt.markLeadAsFail(data).then(response => response);
    },
    commentLead(ctx, data) {
      return useJwt.commentLead(data).then(response => response);
    },
  },
};
