import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFloors(ctx, params) {
      return useJwt.getFloors(params).then(response => response);
    },
    createFloor(ctx, data) {
      return useJwt.createFloor(data).then(response => response);
    },
    updateFloor(ctx, data) {
      return useJwt.updateFloor(data).then(response => response);
    },
    getFloorDetail(ctx, id) {
      return useJwt.getFloorDetail(id).then(response => response);
    },
    deleteFloors(ctx, data) {
      return useJwt.deleteFloors(data).then(response => response);
    },
  },
};
