<template>
  <b-form-group
    :label="label"
    :state="state"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <flat-pickr
      v-model="month"
      class="form-control"
      :placeholder="t('Chọn tháng')"
      :config="config"
      @on-change="onChange"
      @input="onChange"
    />
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { BFormGroup } from 'bootstrap-vue';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import { Vietnamese } from 'flatpickr/dist/l10n/vn';
// import flatpickr from "flatpickr";
import { useUtils as useI18nUtils } from '@core/libs/i18n';

// eslint-disable-next-line no-undef
flatpickr.localize(Vietnamese);
export default {
  components: {
    BFormGroup,
    flatPickr,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      month: this.defaultValue,
    };
  },
  watch: {
    // defaultValue(val) {
    //   this.month = val;
    // },
  },
  methods: {
    onChange() {
      this.$emit('input', this.month);
    },
    onClose() {},
  },
  setup() {
    const config = {
      plugins: [
        // eslint-disable-next-line new-cap
        new monthSelectPlugin({
          shorthand: true, // defaults to false
          dateFormat: "m-Y", // defaults to "F Y"
          altFormat: "F Y", // defaults to "F Y"
        }),
      ],
    };
    const { t } = useI18nUtils();

    return { config, t };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
