<template>
  <b-modal
    id="modal-foward-lead"
    ref="refForwardModal"
    :title="t(itemLocal && itemLocal.isMarkAsFail ? 'Đánh dấu thất bại' : 'Chuyển tiếp trạng thái')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row
          v-if="!itemLocal.isMarkAsFail"
          class="mt-2"
        >
          <b-col>
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary mb-1">
              {{ t('Người phụ trách') }}
            </div>
          </b-col>
        </b-row>
        <b-row v-if="!itemLocal.isMarkAsFail">
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="performer"
              rules="required"
            >
              <select-manager
                v-model="itemLocal.performer"
                :label="t('Người phụ trách')"
                required
                :default-value="itemLocal.performer && itemLocal.performer.user ? [{...itemLocal.performer.user, managerId: itemLocal.performer.id}] : []"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
        </b-row>
        <b-row v-if="itemLocal.isMarkAsFail">
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="failReason"
              rules="required"
            >
              <select-general-data
                v-model="itemLocal.failReason"
                :label="t('Lý do thất bại')"
                data-type="leadFailType"
                required
                enable-add
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group :label="t('Ghi chú')">
              <b-form-textarea
                id="note"
                v-model="itemLocal.note"
                :placeholder="t('Ghi chú')"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectManager from '@/views/components/SelectManager.vue';
import SelectGeneralData from '@/views/components/SelectGeneralData.vue';
import useForwardLeadModal from './useForwardLeadModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    ValidationObserver,
    ValidationProvider,
    SelectManager,
    BFormGroup,
    BFormTextarea,
    SelectGeneralData,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refForwardModal,
      refForm,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      t,
    } = useForwardLeadModal(props, emit, refFormObserver);

    return {
      refForwardModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      refForm,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
};
</script>
