<template>
  <b-modal
    id="modal-lead"
    ref="refModal"
    :title="t('Khách hẹn xem')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="!isLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row class="mt-2">
          <b-col>
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary mb-1">
              {{ isTingTong ? t('Người bắn khách') : t('Người tìm khách') }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <!-- Assignee -->
            <select-manager
              v-model="itemLocal.sale"
              :label="isTingTong ? t('Người bắn khách') :t('Người tìm khách')"
              :default-value="itemLocal.sale && itemLocal.sale.user ? [{...itemLocal.sale.user, managerId: itemLocal.sale.id}] : []"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary mb-1">
              {{ isTingTong ? t('Người dẫn khách') :t('Người phụ trách') }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="performer"
              rules="required"
            >
              <select-manager
                v-model="itemLocal.performer"
                :label="isTingTong ? t('Người dẫn khách') :t('Người phụ trách')"
                required
                :default-value="itemLocal.performer && itemLocal.performer.user ? [{...itemLocal.performer.user, managerId: itemLocal.performer.id}] : []"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary mb-1">
              {{ t('CTV tìm khách') }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="leadCollab"
              rules=""
            >
              <select-general-data
                v-model="itemLocal.leadCollab"
                :default-value="itemLocal.leadCollab"
                :label="t('CTV tìm khách')"
                data-type="leadCollab"
                enable-add
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary mb-1">
              {{ t('Thông tin khách hẹn') }}
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="leadSourceObject"
              rules="required"
            >
              <select-general-data
                v-model="itemLocal.leadSourceObject"
                :default-value="itemLocal.leadSourceObject"
                :label="t('Nguồn khách')"
                data-type="leadSource"
                required
                enable-add
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col md="6">
            <!-- <validation-provider
              #default="validationContext"
              name="tenant"
              rules="required"
            >
              <select-tenant
                v-model="itemLocal.tenant"
                required
                :is-add-lead="true"
                :default-value="itemLocal.tenant"
                :label="t('Khách xem')"
                :enable-add="true"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider> -->
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                :label="t('Họ và tên')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Họ và tên') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  placeholder="Nguyễn Nam Phong"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="phone"
              rules="required"
            >
              <b-form-group
                :label="t('Số điện thoại')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Số điện thoại') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="phone"
                  v-model="itemLocal.phone"
                  placeholder="0868987355"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>
        <!-- <b-row> -->

        <!-- <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="gender"
              rules="required"
            >
              <select-gender
                v-model="itemLocal.genderObject"
                required
                :default-value="itemLocal.genderObject"
                :label="t('Giới tính')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col> -->

        <!-- </b-row> -->

        <!-- Company -->

        <b-row>
          <b-col cols="12">
            <b-form-group :label="t('Ghi chú')">
              <b-form-textarea
                id="note"
                v-model="itemLocal.note"
                :placeholder="t('Ghi chú')"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary mb-1">
              {{ t('Nhu cầu khách thuê') }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="validationContext"
              name="apartment"
              rules="required"
            >
              <select-apartment
                v-model="itemLocal.apartment"
                required
                :filter-active="true"
                :default-value="itemLocal.apartment"
                :label="t('Tòa nhà')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col>
            <select-multiple-room
              v-model="itemLocal.rooms"
              :default-value="itemLocal.rooms"
              :apartment="itemLocal.apartment"
              :label="t('Chọn phòng cần xem')"
              class="select-room"
            />
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col>
            <validation-provider
              #default="validationContext"
              :name="t('Giá thấp nhất')"
              rules="required"
            >
              <number-input
                v-model="itemLocal.expectedMinPrice"
                required
                :default-value="itemLocal.expectedMinPrice"
                :label="t('Giá thấp nhất')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col>
            <validation-provider
              #default="validationContext"
              :name="t('Giá cao nhất')"
              rules="required"
            >
              <number-input
                v-model="itemLocal.expectedMaxPrice"
                required
                :default-value="itemLocal.expectedMaxPrice"
                :label="t('Giá cao nhất')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
        </b-row> -->
        <b-row>
          <b-col>
            <validation-provider
              #default="validationContext"
              :name="t('Thời gian hẹn xem')"
              rules="required"
            >
              <select-date-time
                v-model="itemLocal.expectedVisitTime"
                required
                :default-value="itemLocal.expectedVisitTime"
                :label="t('Thời gian hẹn xem')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormTextarea,
  BFormInput,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectMultipleRoom from '@/views/components/SelectMultipleRoom.vue';
import SelectDateTime from '@/views/components/SelectDateTime.vue';
import SelectManager from '@/views/components/SelectManager.vue';
import SelectGeneralData from '@/views/components/SelectGeneralData.vue';
import useLeadModal from './useLeadModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormInput,
    SelectApartment,
    SelectMultipleRoom,
    SelectDateTime,
    SelectManager,
    SelectGeneralData,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      addVehicle,
      onSubmit,
      removeVehicle,
      isTingTong,
      isVmarket,
      t,
      isLoading,
      onOpen,
    } = useLeadModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      isTingTong,
      isVmarket,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      addVehicle,
      t,
      removeVehicle,
      isLoading,
      onOpen,
    };
  },
};
</script>
