<template>
  <b-modal
    id="modal-cashbook"
    ref="refModal"
    :title="isTingTong ? t('Hình thức thanh toán') : t('Sổ quỹ')"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    scrollable
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Type -->
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="itemLocal.type"
            name="type"
            value="cash"
            class="custom-control-primary"
          >
            {{ t('Tiền mặt') }}
          </b-form-radio>

          <b-form-radio
            v-model="itemLocal.type"
            name="type"
            value="bank"
            class="custom-control-warning"
          >
            {{ t('Ngân hàng') }}
          </b-form-radio>

          <b-form-radio
            v-model="itemLocal.type"
            name="type"
            value="e-wallet"
            class="custom-control-danger"
          >
            {{ t('Ví điện tử') }}
          </b-form-radio>
        </div>
        <!-- Title -->
        <validation-provider
          #default="validationContext"
          name="name"
          rules="required"
        >
          <b-form-group
            :label="t('Tên sổ quỹ')"
            class="mt-2"
          >
            <template
              v-slot:label
            >
              {{ t('Tên sổ quỹ') }}
              <span
                class="text-danger"
              >
                (*)
              </span>
            </template>
            <b-form-input
              id="name"
              v-model="itemLocal.name"
              autofocus
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- BANK -->
        <div v-if="itemLocal.type === 'bank'">
          <validation-provider
            #default="validationContext"
            name="bank"
            rules="required"
          >
            <select-bank
              v-model="itemLocal.bank"
              :required="true"
              :default-value="itemLocal.bank"
              :state="getValidationState(validationContext)"
              :error="validationContext.errors[0]"
            />

          </validation-provider>
          <!-- Bank Account -->
          <validation-provider
            #default="validationContext"
            name="bankAccountNumber"
            rules="required"
          >
            <b-form-group
              :label="t('Số tài khoản')"
            >
              <template
                v-slot:label
              >
                {{ t('Số tài khoản') }}
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <b-form-input
                id="bankAccountNumber"
                v-model="itemLocal.bankAccountNumber"
                :state="getValidationState(validationContext)"
                trim
                placeholder="0011004295197"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Bank Account Holder -->
          <validation-provider
            #default="validationContext"
            name="bankAccountHolder"
            rules="required"
          >
            <b-form-group
              :label="t('Tên chủ tài khoản')"
            >
              <template
                v-slot:label
              >
                {{ t('Tên chủ tài khoản') }}
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <b-form-input
                id="bankAccountHolder"
                v-model="itemLocal.bankAccountHolder"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Bank Name -->
          <!-- <validation-provider
            #default="validationContext"
            name="bankName"
            rules="required"
          >
            <b-form-group
              label="Tên ngân hàng"
            >
              <template
                v-slot:label
              >
                Tên ngân hàng
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <b-form-input
                id="bankName"
                v-model="itemLocal.bankName"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Vietcombank"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->
          <!-- Bank Address -->
          <b-form-group
            :label="t('Chi nhánh')"
          >
            <b-form-input
              id="bankAddress"
              v-model="itemLocal.bankAddress"
              trim
              placeholder=""
            />
          </b-form-group>
        </div>

        <!-- E-WALLET -->
        <div v-if="itemLocal.type === 'e-wallet'">
          <!-- Bank Account -->
          <validation-provider
            #default="validationContext"
            name="bankAccountNumber"
            rules="required"
          >
            <b-form-group
              :label="t('Số tài khoản')"
            >
              <template
                v-slot:label
              >
                {{ t('Số tài khoản') }}
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <b-form-input
                id="bankAccountNumber"
                v-model="itemLocal.bankAccountNumber"
                :state="getValidationState(validationContext)"
                trim
                placeholder="0011004295197"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Bank Account Holder -->
          <validation-provider
            #default="validationContext"
            name="bankAccountHolder"
            rules="required"
          >
            <b-form-group
              :label="t('Tên chủ tài khoản')"
            >
              <template
                v-slot:label
              >
                {{ t('Tên chủ tài khoản') }}
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <b-form-input
                id="bankAccountHolder"
                v-model="itemLocal.bankAccountHolder"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nguyễn Nam Phong"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        </div>

        <validation-provider
          #default="validationContext"
          name="bankAccountHolder"
          rules="required"
        >
          <number-input
            v-model="itemLocal.initialAmount"
            :label="t('Số dư đầu kỳ')"
            :default-value="itemLocal.initialAmount"
            :state="getValidationState(validationContext)"
            :error="validationContext.errors[0]"
          />
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="bankAccountHolder"
          rules="required"
        >
          <select-date
            v-model="itemLocal.initialDate"
            :label="t('Ngày chốt số dư đầu kỳ')"
            :default-value="itemLocal.initialDate"
            :state="getValidationState(validationContext)"
            :error="validationContext.errors[0]"
          />
        </validation-provider>

        <!-- Note -->
        <b-form-group
          :label="t('Mô tả')"
        >
          <b-form-textarea
            id="note"
            v-model="itemLocal.note"
            placeholder=""
            rows="2"
          />
        </b-form-group>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BFormRadio,
  BFormInvalidFeedback,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectBank from '@/views/components/SelectBank.vue';
import useCashbookModal from './useCashbookModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    BFormInvalidFeedback,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,

    NumberInput,
    SelectDate,
    SelectBank,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      isTingTong: process.env.VUE_APP_PROVIDER,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      t,
      onSubmit,
    } = useCashbookModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      t,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
