<template>
  <b-form-group
    :label="label"
    :state="state"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required || banks.length > 0"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="bank"
      v-model="bank"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="nameWithCode"
      :options="banks"
      :placeholder="t('Ngân hàng')"
      @input="bankChanged"
      @open="onOpen"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        {{ t('Không có dữ liệu') }}
      </template>
    </v-select>
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: "Ngân hàng",
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    apiConnectOnly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    filterActive: {
      type: Boolean,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bank: this.defaultValue,
      banks: [],
    };
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  methods: {
    bankChanged() {
      this.$emit('input', this.bank);
    },
    onOpen() {
      this.fetchBanks();
    },
    fetchBanks() {
      if (this.apiConnectOnly) {
        useJwt.getBankAvailableApiConnect({ sort: { field: 'shortName', type: 'asc' } }).then(res => {
          this.banks = res.data.items;
        });
      } else {
        useJwt.getBanks({ sort: { field: 'shortName', type: 'asc' } }).then(res => {
          this.banks = res.data.items;
        });
      }
    },
  },

};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
