import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTenants(ctx, params) {
      return useJwt.getTenants(params).then(response => response);
    },
    fetchAnalytics(ctx, params) {
      return useJwt.getTenantAnalytics(params).then(response => response);
    },
    exportTenants(ctx, params) {
      return useJwt.exportTenants(params).then(response => response);
    },
    createTenant(ctx, data) {
      return useJwt.createTenant(data).then(response => response);
    },
    updateTenant(ctx, data) {
      return useJwt.updateTenant(data).then(response => response);
    },
    importTenants(ctx, data) {
      return useJwt.importTenants(data).then(response => response);
    },
    getTenantDetail(ctx, data) {
      return useJwt.getTenantDetail(data).then(response => response);
    },
    getResidenceInformation(ctx, data) {
      return useJwt.getTenantResidenceInformation(data).then(response => response);
    },
    updateResidenceInformation(ctx, data) {
      return useJwt.updateTenantResidenceInformation(data).then(response => response);
    },
    deleteTenants(ctx, data) {
      return useJwt.deleteTenants(data).then(response => response);
    },
    getCT01PdfUrl(ctx, id) {
      return useJwt.getCT01PdfUrl(id).then(response => response);
    },
  },
};
