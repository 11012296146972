<template>
  <div>
    <b-form-group
      v-if="templates && templates.length > 6"
      :state="state"
    >
      <template
        v-if="label !== null"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="template"
        v-model="template"
        label="name"
        :options="templates"
        :placeholder="t('Mẫu hóa đơn')"
        @input="templateChanged"
        @open="onOpen"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          {{ t('Không có dữ liệu') }}
        </template></v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Mẫu in hóa đơn',
    },
    enableAdd: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      template: this.defaultValue,
      templates: [],
    };
  },
  created() {
    this.fetchTemplates();
  },
  methods: {
    templateChanged() {
      this.$emit('input', this.template);
    },
    fetchTemplates() {
      useJwt.getInvoiceTemplates().then(res => {
        this.templates = res.data.items;
      });
    },
    onOpen() {
      if (this.templates.length === 0) {
        this.fetchTemplates();
      }
    },
    onItemCreated(newItem) {
      this.template = newItem;
      this.templateChanged();
    },
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
