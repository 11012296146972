import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import quotaStoreModule from '../quotaStoreModule';

export default function useQuotaModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = "quota";

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, quotaStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };
  const { t } = useI18nUtils();
  const instructions = computed(() => t(`Ví dụ: Định mức 1 = 50; Đơn giá định mức 1: 1.285. Tức là 50 KW đầu giá là 1.285 Định mức 2 = 50; Đơn giá định mức 2: 1.336. Tức là 50 KW tiếp theo giá là 1.336`));

  const resetModal = () => {
    resetItemLocal();
  };
  const nextQuotaId = ref(1);
  const repeateAgain = () => {
    itemLocal.value.items.push({
      id: (nextQuotaId.value += nextQuotaId.value),
      quantity: '',
      price: '',
    });
  };

  const removeItem = index => {
    itemLocal.value.items.splice(index, 1);
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }
    isSubmitting.value = true;
    const submitData = {
      name: itemLocal.value.name,
      description: itemLocal.value.description,
      items: itemLocal.value.items.map(object => ({ quantity: object.quantity, price: object.price })),
    };
    if (itemLocal.value.id && itemLocal.value.id > 0) {
      submitData.id = itemLocal.value.id;
      store
        .dispatch('quota/updateQuota', submitData)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('quota/createQuota', submitData)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    instructions,
    resetItemLocal,
    resetModal,
    onSubmit,
    repeateAgain,
    removeItem,
    t,
  };
}
