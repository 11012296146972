import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { parseDateToString } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import leadStoreModule from '../leadStoreModule';

export default function useLeadModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'lead';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, leadStoreModule);
  }
  const { t } = useI18nUtils();
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
  const isVmarket = process.env.VUE_APP_PROVIDER === 'vmarket';

  const toastification = toast();
  const isSubmitting = ref(false);
  const isLoading = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const itemId = computed(() => (props.item && props.item.id ? props.item.id : null));

  const fetchDetailData = () => {
    if (itemId.value) {
      isLoading.value = true;
      store.dispatch('lead/getLeadDetail', itemId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const onOpen = () => {
    resetModal();
    fetchDetailData();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      saleId: itemLocal.value.sale ? itemLocal.value.sale.managerId : null,
      tenantId: itemLocal.value.tenant ? itemLocal.value.tenant.id : null,
      name: itemLocal.value.name,
      phone: itemLocal.value.phone,
      performerId: itemLocal.value.performer ? itemLocal.value.performer.managerId : null,
      leadSourceId: itemLocal.value.leadSourceObject ? itemLocal.value.leadSourceObject.id : null,
      leadCollabId: itemLocal.value.leadCollab ? itemLocal.value.leadCollab.id : null,
      note: itemLocal.value.note,
      apartmentId: itemLocal.value.apartment.id,
      roomIds: itemLocal.value.rooms && itemLocal.value.rooms.length > 0 ? itemLocal.value.rooms.map(obj => obj.id) : [],
      // expectedMinPrice: itemLocal.value.expectedMinPrice,
      // expectedMaxPrice: itemLocal.value.expectedMaxPrice,
      expectedVisitTime: parseDateToString(itemLocal.value.expectedVisitTime, "DD-MM-YYYY HH:mm"),
    };

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      store
        .dispatch('lead/updateLead', { data, id: itemLocal.value.id })
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('lead/createLead', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  const addVehicle = () => {
    if (itemLocal.value.vehicles) {
      itemLocal.value.vehicles.push({
        id: 0,
        type: null,
        licensePlate: '',
        name: '',
      });
    } else {
      itemLocal.value.vehicles = [{
        id: 0,
        type: null,
        licensePlate: '',
        name: '',
      }];
    }
  };

  const removeVehicle = index => {
    itemLocal.value.vehicles.splice(index, 1);
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    addVehicle,
    removeVehicle,
    isTingTong,
    isVmarket,
    t,
    isLoading,
    onOpen,
  };
}
